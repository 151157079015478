// Main scripts file
import './js/index';
import './search';

// Main styles file
import './scss/index.scss';


// Font awesome icons
import './js/fontawesome';

function importAll(r) {
  const images = {};
  // eslint-disable-next-line no-unused-vars,array-callback-return
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}

// eslint-disable-next-line no-unused-vars
const images = importAll(require.context('./images', false, /\.(png|jpe?g|svg)$/));
